.App {
  margin: 0 auto;
  padding: 30px 50px;
  min-height: 100vh;
  max-width: 1300px;
  width: 80vw;
  text-align: center;
  background: white;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 10px 5px 5px grey, -10px 5px 5px grey;
}

#root {
  background: #F4D35E;
  padding-top: 20px;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
}

li {
  list-style: none;
}

h3 {
  font-weight: 100;
  text-align: left;
  line-height: 28px;
}

#intro {
  display: flex;
  margin: 50px 0px 0px 0px;
}

#hello {
  text-align: left;
  width: 60%;
  margin-left: 2%;
}

#hello h2 {
  width: 60%;
  line-height: 30px;
}

#intro h3, .contact-blurb h3 {
  width: 80%;
  margin-top: 0
  
}

.skills {
  text-align: center;
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin-left: 10%;
}

.about {
  margin-left: 2%;
  text-align: left;
}

.navbar {
 text-align: right;
}

.navbar a button {
  background: none;
  border: none;
  font-size: 18px;
  font-weight: bolder;
  margin: 0 15px;
  cursor: pointer;
}

p, li, h3, h2, h1, button, a {
  font-family: 'Share Tech Mono', monospace;
}

.image {
  width: 300px;
  border-radius: 10px;
}

#headshot {
  height: 240px;
  border-radius: 50%;
}

.blurb-links-holder {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
}

.link-holder {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.projects {
  max-width: 1300px;
  width: 80vw;
}

.projects-holder {
  display: flex;
  justify-content: space-evenly;
  flex-flow: wrap;
}

.project {
  border-radius: 5%;
  width: 350px;
  margin-top: 10px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  box-shadow: 5px 5px 5px #E3655B, -5px 5px 5px #E3655B;

}
.project:hover {
  box-shadow: 10px 5px 5px #E3655B, -10px 5px 5px #E3655B;
}

.header h1 {
  margin: 0;
}

.header {
  max-width: 1300px;
  width: 80vw;
  padding-top: 30px;
}

.contact {
  text-align: left;
}


.link-holder button, .link-holder-short button, #hello button {
  background: rgb(243 211 94);
  border: rgb(243 211 94) solid 2px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  transition-duration: 0.4s;
}

.link-holder button:hover, .link-holder-short button:hover, #hello button:hover { 
  background-color: white;
}

#contact-title {
  margin-left: 2%;
}

.contact-link-holder {
  width: 300px;
  display: flex;
  margin-right: auto;
  flex-direction: column;
  align-items: flex-start;
}

.contact-link {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-content: center;
}

.contact-link a {
  align-self: center;
  font-size: 22px;
  text-decoration: none;
  margin-left: 10px;
  color: #E3655B;
}

.contact-section {
  display: flex;
}

.contact-blurb {
  width: 60%;
  margin-left: 2%;
}

.logo {
  width: 50px;
}

.component-title {
  text-align: left;
  margin: 50px 0 40px 0;
}

#project-title {
  margin-left: 2%;
}